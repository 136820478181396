import { h } from "preact";
import AppStores from "../custom/ds/components/Appstores";
import { useContext } from "preact/hooks";
import { Config } from "../index";

const DeeplinkInfo = () => {
  if (process.env.NODE_ENV === "production") {
    return <AppStores config={config} />;
  }

  const { config } = useContext(Config)

  console.log(config)

  return (
    <>
      <h1>Test TOC</h1>
      <p>This is only shown on develop</p>
      <table>
        {Object.keys(config.routes).map((key) => {
          const route = config.routes[key];
          const link = config.namespace
            ? `/${config.namespace}/${key}`
            : `/${key}`;
          return (
            <tr>
              <td>{route.title}</td>
              <td>
                <a href={link}>{link}</a>
              </td>
            </tr>
          );
        })}
      </table>
    </>
  );
};

export default DeeplinkInfo;
