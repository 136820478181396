import { h } from "preact";

import desktop1x from "../images/hero-banner-desktop.jpg";
import desktop2x from "../images/hero-banner-desktop@2x.jpg";
import desktop3x from "../images/hero-banner-desktop@3x.jpg";

import mobile1x from "../images/hero-banner-mobile.jpg";
import mobile2x from "../images/hero-banner-mobile@2x.jpg";
import mobile3x from "../images/hero-banner-mobile@3x.jpg";

const Hero = () => {
  return (
    <div class="hero">
      <picture>
        <source
          media="(max-width: 375px)"
          srcset={`${mobile1x} 1x, ${mobile2x} 2x, ${mobile3x} 3x`}
        />
        <source
          media="(min-width: 376px)"
          srcset={`${desktop1x} 1x, ${desktop2x} 2x, ${desktop3x} 3x`}
        />
        <img src={desktop1x} class="heroImage" />
      </picture>
    </div>
  );
};

export default Hero;
