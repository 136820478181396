import { h } from "preact";
import { useContext } from "preact/hooks";
import { Config } from "../index";

const DeeplinkButton = () => {
  const { config, urls } = useContext(Config);
  const { origin, pathname } = window.location;

  const isSecondary = origin.match(urls.secondary);

  return (
    !isSecondary && (
      <a class="button" href={`${urls.secondary}${pathname}`}>
        {config.button}
      </a>
    )
  );
};

export default DeeplinkButton;
