export default {
  button: "Open Betwarrior App",
  routes: {
    "users/:id": {
      title: "You’ve been invited by a friend to BetWarrior.",
      description:
        "BetWarrior has build a community for friends to challenge and share the best picks. Join today the ultimate betting experience!",
    },
  },
};
