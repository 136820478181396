import { h } from "preact";
import { useContext } from "preact/hooks";
import { Config } from "../../../index";

const AppStores = () => {
  const { config } = useContext(Config);
  const { ios, android } = config;
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;
  const iOSDevice =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const notAndroidiOS = !isAndroid && !iOSDevice;

  return (
    <div class="stores">
      {ios && (iOSDevice || notAndroidiOS) && (
        <a href={ios.href}>
          <img src={ios.logo} />
        </a>
      )}
      {android && (isAndroid || notAndroidiOS) && (
        <a
          class="button"
          href={android.href}
          rel="noopener noreferrer"
          target="_blank"
        >
          Oddset for Android
        </a>
      )}
    </div>
  );
};

export default AppStores;
