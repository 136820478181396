import { h } from "preact";
import info from "../images/info.svg";

const Install = () => {
  return (
    <>
      <div class="box">
        <h2>How to install?</h2>
        <ol class="list">
          <li>Download the APK file</li>
          <li>
            When installing it, you might get the message: “Installation is
            blocked”
          </li>
          <li>If that happens, go to “Settings” and select “Security”</li>
          <li>Allow installing apps from other sources and download again</li>
        </ol>
      </div>
      <div class="box left vertical">
        <img src={info} />
        &nbsp;
        <b>IMPORTANT!&nbsp;</b>Re-open this page after installing APK
      </div>
      <div>
        <a
          class="button download"
          href="https://android-download.betwarrior.bet/betwarrior-latest.apk"
        >
          Download Android App
        </a>
      </div>
    </>
  );
};

export default Install;
